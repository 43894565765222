<template>
  <div class="table_wrapper">
    <div class="tab_wrapper d-flex flex-wrap w-100 align-items-center mb-2">
      <v-btn
        v-on:click="getDataFromApi(0)"
        color="primary"
        :loading="tabIndis[0].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[0].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(1)"
        color="success"
        :loading="tabIndis[1].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[1].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(2)"
        color="error"
        :loading="tabIndis[2].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[2].name }}</span>
      </v-btn>
    </div>

    <div class="filter_wrapper mb-2" v-if="mfieldsFilter">
      <v-btn icon v-on:click="resetFilter">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-chip
        v-for="(a, i) in mfields"
        :key="i"
        v-show="
          (a.field == 'is_seller' && a.value == true) ||
            (a.field == 'is_buyer' && a.value == true)
        "
        >{{
          a.field == "is_seller" && a.value == true
            ? "Seller"
            : a.field == "is_buyer"
            ? "Buyer"
            : "Both"
        }}</v-chip
      >
    </div>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      show-select
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:[`item.c2`]="{ item }">
        <v-avatar size="50">
          <v-img :src="item.c2" max-width="50" max-height="50"></v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.c5`]="{ item }">
        <v-chip
          max-width="100"
          small
          v-if="
            (item.item.is_seller &&
              item.item.store &&
              item.item.store.domain) ||
              item.item.member_id
          "
        >
          <a
            class="store_link"
            :href="
              item.item.store.domain
                ? 'https://' + item.item.store.domain + '.gowawe.com'
                : 'https://gowawe.com/en/store/' + item.item.store.member_id
            "
            target="_blank"
            ><v-icon left> mdi-web </v-icon>{{ item.c5 }}</a
          >
        </v-chip>
        <v-chip :color="'red'" small dark v-else>
          No Store
        </v-chip>
      </template>

      <template v-slot:[`item.c7`]="{ item }">
        <v-chip
          :color="'red'"
          dark
          v-on:click="setFilter('is_buyer')"
          v-if="item.item.is_buyer == true && item.item.is_seller == false"
        >
          {{ item.c7 }}
        </v-chip>
        <v-chip
          :color="'green'"
          dark
          v-on:click="setFilter('is_seller')"
          close
          close-icon="mdi-tag-arrow-right"
          @click:close="$router.push('/products/' + item.item.id)"
          v-else-if="item.item.is_buyer == false && item.item.is_seller == true"
        >
          {{ item.c7 }}
        </v-chip>

        <v-chip
          :color="'primary'"
          dark
          v-on:click="setFilter('both')"
          close
          close-icon="mdi-tag-arrow-right"
          @click:close="$router.push('/products/' + item.item.id)"
          v-else-if="item.item.is_buyer == true && item.item.is_seller == true"
        >
          {{ item.c7 }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Members</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>{{ tabIndis[activeTab].name }}</v-toolbar-title>

          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="success"
            class="ml-2"
            v-on:click="selectedAction('approve')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Approve Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="error"
            class="ml-2"
            v-on:click="selectedAction('reject')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Reject Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>

          <v-layout wrap>
            <v-col cols="6">
              <v-switch v-model="search_type" label="Is Company"></v-switch>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                clearable
                v-on:input="tableSearch"
                append-icon="mdi-magnify"
                :label="
                  search_type
                    ? 'Search for Company Name'
                    : 'Search for User Name'
                "
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-layout>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'members/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip top v-if="activeTab == 0 || activeTab == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'approved')"
              color="success"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>

        <v-tooltip right v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'rejected')"
              color="error"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Members",
  data() {
    return {
      search_type: false,
      dialog: false,
      selected: [],
      mfieldsFilter: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      tabIndis: [
        {
          index: 0,
          statu: false,
          val: "null",
          loader: true,
          name: "Pending",
        },
        {
          index: 1,
          statu: false,
          val: 1,
          loader: false,
          name: "Approved",
        },
        {
          index: 2,
          statu: false,
          val: "0",
          loader: false,
          name: "Rejected",
        },
      ],
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "Photo", value: "c2", sortable: false },
        { text: "User ID", value: "c3", sortable: false },
        { text: "Member ID", value: "c3s", sortable: false },
        { text: "Name", value: "c4", sortable: false },
        { text: "Phone", value: "c4v", sortable: false },
        { text: "Store Name", value: "c5", sortable: false },
        { text: "Email", value: "c6", sortable: false },
        { text: "Type", value: "c7", sortable: false },
        { text: "Subscriptions", value: "c8", sortable: false },
        { text: "Country", value: "c9", sortable: false },
        { text: "Is Aproved", value: "c10", sortable: false },
        { text: "Last Login", value: "c12", sortable: false },
        { text: "Created At", value: "c11", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.item;
        });

        let url = "";

        if (type == "delete") {
          url = process.env.VUE_APP_API_URL + "member/bulkdelete";
        } else if (type == "approve") {
          url = process.env.VUE_APP_API_URL + "member/approve";
        } else if (type == "reject") {
          url = process.env.VUE_APP_API_URL + "member/reject";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },

    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      const deletedData = this.dialogAction.data.map((b) => {
        return b.id;
      });

      axios
        .post(this.dialogAction.url, {
          id: deletedData,
          api_token: this.token,
        })
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => {
          console.log(err);
        });

      this.dialogAction.data.forEach((item) => {
        const dataToX = item.user_id.toString();

        if (!dataToX) {
          return false;
        }

        if (this.dialogAction.type == "approve") {
          let notifyMessage = {
            to: dataToX,
            type: "member",
            statu: true,
          };

          this.$socket.emit("sendNotify", notifyMessage);
        } else if ((this.dialogAction.type = "reject")) {
          let notifyMessage = {
            to: dataToX,
            type: "member",
            statu: false,
          };

          this.$socket.emit("sendNotify", notifyMessage);
        }
      });
    },
    resetFilter: function() {
      let index1 = this.mfields.findIndex((a) => a.field == "is_seller");
      this.mfields.splice(index1, 1);
      let index2 = this.mfields.findIndex((a) => a.field == "is_buyer");
      this.mfields.splice(index2, 1);
      this.mfieldsFilter = false;
      this.getDataFromApi(this.activeTab);
    },
    setFilter: function(type) {
      switch (type) {
        case "is_buyer":
          if (this.mfields.filter((a) => a.field == type).length == 0) {
            this.mfields.push(
              {
                field: "is_seller",
                op: "=",
                value: false,
              },
              {
                field: "is_buyer",
                op: "=",
                value: true,
              }
            );
          }
          break;
        case "is_seller":
          if (this.mfields.filter((a) => a.field == type).length == 0) {
            this.mfields.push(
              {
                field: "is_seller",
                op: "=",
                value: true,
              },
              {
                field: "is_buyer",
                op: "=",
                value: false,
              }
            );
          }
          break;
        case "both":
          if (this.mfields.filter((a) => a.field == type).length == 0) {
            this.mfields.push(
              {
                field: "is_seller",
                op: "=",
                value: true,
              },
              {
                field: "is_buyer",
                op: "=",
                value: true,
              }
            );
          }
          break;
      }
      this.mfieldsFilter = true;
      this.getDataFromApi(this.activeTab);
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    process: function(item, type) {
      const dataTo = item.id.toString();

      let notifyMessage;

      let data = [
        {
          api_token: this.token,
          id: [item.id],
        },
      ];

      let url = "";
      if (type == "approved") {
        url = process.env.VUE_APP_API_URL + "member/approve";

        notifyMessage = {
          to: dataTo,
          title: "New Member Notify!",
          name: "Great!",
          message: {
            text: "Your account has been approved.",
          },
        };
      } else if (type == "rejected") {
        url = process.env.VUE_APP_API_URL + "member/reject";

        notifyMessage = {
          to: dataTo,
          title: "New Member Notify!",
          name: "We are sorry..",
          message: {
            text: "Your account has been rejected.",
          },
        };
      }

      this.$socket.emit("sendNotify", notifyMessage);

      axios.post(url, data[0]).then((res) => {
        this.getDataFromApi(this.activeTab);
      });
    },
    getDataFromApi(a = null) {
      this.activeTab = a;
      this.tabIndis[a].loader = true;
      this.loading = true;

      this.mfields.find((a) => {
        switch (a.field) {
          case "is_approved":
            a.value = this.tabIndis[this.activeTab].val;
            break;
          case "term":
            a.value = this.search;
            break;
          case "status":
            a.value = "all";
            break;
        }
      });

      this.fakeApiCall().then((data) => {
        this.desserts = [];

        data.itemsR.forEach((item, index) => {
          let subscription = "";

          item.subscriptions.forEach((item) => {
            if (item.status == "active") {
              subscription += item.package.title + " ";
            }
          });

          const key = {
            c2: item?.store?.contact_photo
              ? item?.store?.contact_photo
              : "https://via.placeholder.com/60x60",
            c3: item.user_id,
            c3s: item.id,
            c4: (item.first_name || "") + " " + item.last_name,
            c4v: "+" + item.country_code + " " + item.phone,
            c5: item?.store?.company_name,
            c6: item.user ? item.user.email : "",
            c7:
              item.is_seller && item.is_buyer
                ? "Both"
                : item.is_seller
                ? "Seller"
                : "Buyer",
            c8: subscription,
            c9:
              (item.country ? item.country.name : "") + " - " + item.city.name,
            c10: item.is_approved ? "Yes" : "No",
            c11: item.created_at,
            c12: "",
            actions: null,
            id: item.id,
            item: item,
          };
          this.desserts.push(key);
        });

        this.tabIndis.find((x) => (x.loader = false));
        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 100);
        });
      });
    },
    getDesserts() {
      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      let fields = this.mfields;
      let filterData;

      if (this.search_type) {
        filterData = {
          keyword: this.search,
          fields,
        };
      } else {
        filterData = {
          fields,
          sort: sort,
          type: null,
        };
      }

      let path;
      let approved = this.tabIndis[this.activeTab].index;

      if (this.search_type) {
        path = "member/filter-store?is_approved=" + approved + "page=";
      } else {
        path = "member/filter?page=";
      }

      return axios
        .post(
          process.env.VUE_APP_API_URL +
            path +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          filterData
        )
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.mfields = [
      {
        field: "is_approved",
        op: "=",
        value: this.tabIndis[this.activeTab].val,
      },
      {
        field: "term",
        op: "LIKE",
        value: this.search,
      },
      {
        field: "status",
        op: "=",
        value: "all",
      },
    ];
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style scoped>
.store_link {
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding: 0 5px;
}
</style>
